import { useEffect, useState } from 'react'

import { IStructure, Portal } from 'src/interfaces/structure'

import { listFavorites, useObtainStructure } from 'src/api/graphql'

import { QLoading } from 'src/shared/components'

import config from 'src/config/app'

enum Section {
  banners = 'banners',
  brands = 'brands',
  products = 'products',
  categories = 'categories',
}

interface IFavorite {
  section: Section
  names: string[]
}

const HomeLayout = () => {
  const [favorites, setFavorites] = useState<IFavorite[]>([])
  const [structure, setStructure] = useState<IStructure | null>(null)

  const qFavorites = listFavorites()
  const qStructure = useObtainStructure({ portal: Portal.store })

  useEffect(() => {
    if (!qFavorites.loading) {
      if (qFavorites.data) setFavorites([...qFavorites.data.listFavorites])
    }
  }, [qFavorites.loading, qFavorites.data])

  useEffect(() => {
    if (!qStructure.loading && qStructure.data) {
      setStructure(qStructure.data?.obtainStructure)
    }
  }, [qStructure.loading, qStructure.data])

  useEffect(() => {
    window.location.href = config.platform.landing ?? '/'
  }, [])

  return (
    <QLoading loading={true}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <span>¡Bienvenido!</span>
      </div>
    </QLoading>
  )
}

export default HomeLayout
