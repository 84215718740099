import styled from 'styled-components'

import dimens from 'src/styles/theme/dimens'
import colors from 'src/styles/theme/colors'
import sizes from 'src/styles/theme/sizes'

const fontFamily = 'Poppins'
export const FooterStyled = styled.div`
  padding: 65px 40px;
  background-color: ${colors.footerBackground};

  .desc {
    margin: ${dimens.marginx} 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }
  .grid-space {
    display: flex;
    gap: ${dimens.marginy};
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .main-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    width: 100%;
    margin-bottom: 80px;
    @media screen and (max-width: ${sizes.sm}) {
      text-align: center;
    }
  }
  .footer-copy {
    margin-top: 40px;
  }

  p {
    @media screen and (max-width: ${sizes.sm}) {
      text-align: center;
    }
  }
`

export const NavFooter = styled.nav`
  .nav-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 25px;
    font-family: ${fontFamily};
    color: ${colors.black};
  }

  .nav-list {
    display: flex;
    grid-gap: 25px;
    flex-direction: column;
    &--social {
      flex-direction: row;
    }
    li a {
      color: ${colors.black};
      text-decoration: none;
      &:hover {
        color: ${colors.blueHover};
      }
    }
  }

  @media screen and (max-width: ${sizes.sm}) {
    text-align: center;
  }
`
