import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { LeftOutlined } from '@ant-design/icons'

import { useDialog } from 'src/shared/hooks'
import { QButton } from 'src/shared/components'

import { MenuMobileItem } from './MenuItem'
import { useListFilters } from '../hooks/useListFilters'
import { BrandsPanelContent } from '../MenuPanel/BrandsPanelContent'
import { CategoriesPanelContent } from '../MenuPanel/CategoriesPanelContent'

import config from 'src/config/app'
import {
  MenuMobileItemStyled,
  MenuMobileStyled,
  BackButtonWrapStyled,
  MenuSubItemWrapperStyled,
} from './styles'

export enum MenuMobileCollapsiblesEnum {
  categories = 'categories',
  brands = 'brands',
}
interface MenuMobileProps {
  modalReq: ReturnType<typeof useDialog>
  handleClose: () => void
}

export const MenuMobile: FC<MenuMobileProps> = (props) => {
  const [collapsibleSlug, setCollapsibleSlug] = useState<
    keyof typeof MenuMobileCollapsiblesEnum | null
  >(null)
  const filtersData = useListFilters()

  if (collapsibleSlug) {
    return (
      <>
        <BackButtonWrapStyled>
          <QButton
            type="link"
            onClick={() => {
              setCollapsibleSlug(null)
            }}
            icon={<LeftOutlined />}
          >
            Volver
          </QButton>
        </BackButtonWrapStyled>
        {collapsibleSlug === 'categories' ? (
          <>
            <MenuSubItemWrapperStyled>
              <CategoriesPanelContent
                categories={filtersData[collapsibleSlug]}
                handleClose={props.handleClose}
              />
            </MenuSubItemWrapperStyled>
          </>
        ) : (
          <>
            <MenuSubItemWrapperStyled>
              <BrandsPanelContent
                brandsByCategory={filtersData[collapsibleSlug]}
                handleClose={props.handleClose}
              />
            </MenuSubItemWrapperStyled>
            <Link to={'/providers'}>
              <QButton block equip="secondary" onClick={props.handleClose}>
                Ver todas las marcas
              </QButton>
            </Link>
          </>
        )}
      </>
    )
  }

  return (
    <MenuMobileStyled>
      <MenuMobileItemStyled onClick={props.handleClose}>
        <Link to={'/'}>Inicio</Link>
      </MenuMobileItemStyled>
      <MenuMobileItem
        label="Marcas"
        slug={'brands'}
        onSelect={(slug) => setCollapsibleSlug(slug)}
      />
      <MenuMobileItem
        label="Categorías"
        slug={'categories'}
        onSelect={() => setCollapsibleSlug('categories')}
      />
      <MenuMobileItemStyled onClick={props.handleClose}>
        <Link to={'/products'}>Productos</Link>
      </MenuMobileItemStyled>
      <MenuMobileItemStyled>
        <a target="_blank" href={`${config.files.storePDF}`}>
          Catálogo PDF
        </a>
      </MenuMobileItemStyled>
      <MenuMobileItemStyled
        onClick={() => {
          props.modalReq.handleOpen()
          props.handleClose()
        }}
      >
        Crear Requerimiento
      </MenuMobileItemStyled>
    </MenuMobileStyled>
  )
}
