import { Button, Drawer, Input } from 'antd'
import { FilterOutlined } from '@ant-design/icons'
import {
  HeaderStyled,
  ProvidersPageBodyStyled,
  ProvidersWrapper,
  WrapperFilterDesktopStyled,
} from './styles'

import Container from 'src/shared/components/Container'
import { ProviderList } from '../ProviderList'
import { ProviderFilters } from '../ProviderFilters'
import { ChangeEvent, useEffect, useState } from 'react'
import { listCategories } from 'src/api/graphql'
import { useDialog } from 'src/shared/hooks'

const Providers = () => {
  const [brands, setBrands] = useState<any[]>([])
  const [brandsFiltered, setBrandsFiltered] = useState<any[]>([])
  const [categoriesToFilter, setCategoriesToFilter] = useState<string[]>([])
  const [categories, setCategories] = useState<any[]>([])
  const [search, setSearch] = useState<string>('')
  const filterDrawer = useDialog()
  const { data: categoriesData, loading: categoriesLoading } = listCategories(
    { page: 1, perPage: 500, sortField: 'order', sortOrder: 'asc' },
    { q: '' }
  )

  const handleBrandsByCategories = (categories: string[]) => {
    if (!categories.length) {
      setBrandsFiltered([...brands])
      return
    }
    const brs: any[] = []
    categoriesData.listCategories.categories.forEach(({ name, storeBrandsEntity }: any) => {
      if (categories.includes(name)) brs.push(...storeBrandsEntity)
    })
    const adaptedBrands: any[] = []
    brs.forEach((itm: any) => {
      const found = adaptedBrands.find((sm: any) => sm.name == itm.name)
      if (!found) adaptedBrands.push(itm)
    })
    setBrandsFiltered(adaptedBrands)
  }

  useEffect(() => {
    if (!categoriesData && categoriesLoading) return
    const aux: any[] = []
    categoriesData.listCategories.categories.forEach(({ storeBrandsEntity }: any) => {
      aux.push(...storeBrandsEntity)
    })
    const adaptedBrands: any[] = []
    aux.forEach((itm: any) => {
      const found = adaptedBrands.find((sm: any) => sm.name == itm.name)
      if (!found) adaptedBrands.push(itm)
    })

    const adaptedCategories = categoriesData.listCategories.categories
      .filter((category: any) => category.storeBrandsEntity.length)
      .map((category: any) => ({
        name: category.name,
        countBrands: category.storeBrandsEntity.length,
      }))

    setCategories(adaptedCategories)
    setBrands([...adaptedBrands])
    setBrandsFiltered([...adaptedBrands])
  }, [categoriesData, categoriesLoading])

  function debounce(func: Function, delay: number) {
    let timer: NodeJS.Timeout
    return function (...args: any) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        func(...args)
      }, delay)
    }
  }

  const debouncedOnChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (!value) {
      if (categoriesToFilter.length) handleBrandsByCategories(categoriesToFilter)
      else setBrandsFiltered([...brands])
      return
    }
    const regex = new RegExp(value, 'i')
    const brs = brandsFiltered.filter((brand: any) => regex.test(brand.name))
    setBrandsFiltered([...brs])
  }, 300)

  return (
    <ProvidersWrapper>
      <Container>
        <HeaderStyled>
          <span className="providers-title">Marcas</span>
          <Button
            className="providers-filter-btn"
            type="text"
            icon={<FilterOutlined />}
            onClick={filterDrawer.handleOpen}
          >
            Filtro
          </Button>
          <Input
            type="text"
            placeholder="Escribe el nombre de la marca..."
            className="providers-search"
            value={search}
            onChange={(e: any) => {
              setSearch(e.target.value)
              debouncedOnChange(e)
            }}
          />
        </HeaderStyled>
        <ProvidersPageBodyStyled>
          <WrapperFilterDesktopStyled>
            <ProviderFilters
              values={categoriesToFilter}
              categories={categories}
              onChange={(values: string[]) => {
                setSearch('')
                setCategoriesToFilter(values)
                handleBrandsByCategories(values)
              }}
              onCleanFilters={() => {
                setCategoriesToFilter([])
                setBrandsFiltered([...brands])
              }}
            />
          </WrapperFilterDesktopStyled>
          <ProviderList
            providers={brandsFiltered?.map((brand) => ({
              id: brand._id,
              name: brand.name,
              slug: brand.name,
              img: brand.logoUrl,
              hasStore: brand.hasStore,
            }))}
          />
        </ProvidersPageBodyStyled>
      </Container>
      <Drawer open={filterDrawer.open} onClose={filterDrawer.handleClose}>
        <ProviderFilters
          values={categoriesToFilter}
          categories={categories}
          onChange={(values) => setCategoriesToFilter(values)}
          onCleanFilters={() => setCategoriesToFilter([])}
        />
      </Drawer>
    </ProvidersWrapper>
  )
}

export default Providers
