import { lazy } from 'react'
import { RouteObject } from 'react-router'

import { AuthRoutes, PublicRoutes } from './interfaces/types/app'

import { Root } from 'src/pages/root/Root'

import HomePage from 'src/pages/Home'
import CartPage from 'src/pages/Cart'
import StorePage from 'src/pages/Store'
import ProductsPage from 'src/pages/Products'
import ProvidersPage from './pages/Providers'
import LoggedPage from './pages/Logged'
import LogoutPage from './pages/Logout'
import RegisterPage from './pages/Register'
import CompletePage from './pages/Auth/Complete'
import ConfirmPage from './pages/Auth/Confirm'

const CheckoutPage = lazy(() => import('./pages/Checkout'))
const FinishPage = lazy(() => import('./pages/Finish'))
const ProductDetailsPage = lazy(() => import('./pages/ProductsDetails'))
const GenerateRequirementPage = lazy(async () => await import('./pages/GenerateRequirement'))
const PrivacyPoliciesPage = lazy(async () => await import('./pages/Policies/Privacy'))
const TermsPoliciesPage = lazy(async () => await import('./pages/Policies/Terms'))

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '*',
        element: <HomePage />,
      },
      {
        path: '',
        element: <HomePage />,
      },
      {
        path: PublicRoutes.HOME,
        element: <HomePage />,
      },
      {
        path: PublicRoutes.PROVIDERS,
        element: <ProvidersPage />,
      },
      {
        path: PublicRoutes.PROVIDERS_DETAIL,
        element: <StorePage />,
      },
      {
        path: PublicRoutes.PRODUCTS,
        element: <ProductsPage />,
      },
      {
        path: PublicRoutes.CART,
        element: <CartPage />,
      },
      {
        path: PublicRoutes.CHECKOUT,
        element: <CheckoutPage />,
      },
      {
        path: PublicRoutes.FINISH,
        element: <FinishPage />,
      },
      {
        path: PublicRoutes.PRODUCTS_DETAIL,
        element: <ProductDetailsPage />,
      },
      {
        path: PublicRoutes.REQUIREMENTS_GENERATE,
        element: <GenerateRequirementPage />,
      },
      {
        path: PublicRoutes.PRIVACY_POLICIES,
        element: <PrivacyPoliciesPage />,
      },
      {
        path: PublicRoutes.TERMS_POLICIES,
        element: <TermsPoliciesPage />,
      },
      {
        path: PublicRoutes.REGISTER,
        element: <RegisterPage />,
      },
      {
        path: PublicRoutes.COMPLETE,
        element: <CompletePage />,
      },
      {
        path: PublicRoutes.CONFIRM,
        element: <ConfirmPage />,
      },
    ],
  },
  {
    path: AuthRoutes.LOGGED,
    element: <LoggedPage />,
  },
  {
    path: AuthRoutes.LOGOUT,
    element: <LogoutPage />,
  },
]
