import { FC, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Menu as AntMenu, MenuProps, Button } from 'antd'
import { ArrowRightOutlined, DownloadOutlined } from '@ant-design/icons'

import { useDialog } from 'src/shared/hooks'

import config from 'src/config/app'
import { MenuStyled } from './styles'
import { MenuPanel } from './MenuPanel'
import { useListFilters } from './hooks/useListFilters'
import { BrandsPanelContent } from './MenuPanel/BrandsPanelContent'
import { RequirementFormModal } from 'src/shared/components/RequirementFormModal'
import { CategoriesPanelContent } from './MenuPanel/CategoriesPanelContent'

import Storage from 'src/shared/helpers/storage'
import { QNotify } from 'src/shared/components'
import { createRequirement } from 'src/api/graphql'
import { Platforms } from 'src/interfaces/types/app'

const Menu: FC<{ modalReq: ReturnType<typeof useDialog> }> = (props) => {
  const [menuRect, setMenuRect] = useState<DOMRect>()
  const menuRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const catPanel = useDialog()
  const brandPanel = useDialog()
  const filters = useListFilters()

  const [addRequirement, { loading }] = createRequirement()

  const createReq = () => {
    const accountId = Storage.getAccountId()
    if (accountId) {
      addRequirement({
        context: { clientName: Platforms.business },
        variables: { input: { accountId, origin: 'landing' } },
      })
        .then((data) => {
          const requirementId = data.data.addRequirement._id
          navigate(`/requirements/generate/${requirementId}?accountId=${accountId}`)
        })
        .catch((e) => {
          QNotify({
            message: e?.message,
            type: 'error',
          })
        })
    } else {
      props.modalReq.handleOpen()
    }
  }

  const selectedKey = pathname === '/' ? '/home' : pathname

  const items: MenuProps['items'] = [
    {
      label: 'Inicio',
      key: '/home',
      onClick: () => navigate('/home'),
    },
    {
      label: (
        <div>
          <Button onClick={() => navigate('/providers')} type="text" icon={<ArrowRightOutlined />}>
            Marcas
          </Button>
          <MenuPanel
            isOpen={brandPanel.open}
            style={{ top: menuRect?.bottom, height: `calc(100vh - ${menuRect?.bottom}px)` }}
            title={'Marcas'}
            content={<BrandsPanelContent brandsByCategory={filters.brands} />}
          />
        </div>
      ),
      key: '/brands',
      onMouseEnter: () => {
        brandPanel.handleOpen()
      },
      onMouseLeave: () => {
        brandPanel.handleClose()
      },
    },
    {
      label: (
        <>
          <Button type="text" icon={<ArrowRightOutlined />}>
            Categorías
          </Button>
          <MenuPanel
            isOpen={catPanel.open}
            style={{
              top: menuRect?.bottom,
              height: `calc(100vh - ${menuRect?.bottom}px)`,
            }}
            title={'Categorías'}
            content={<CategoriesPanelContent categories={filters.categories ?? []} />}
          />
        </>
      ),
      key: 'categories',
      onMouseEnter: () => {
        catPanel.handleOpen()
      },
      onMouseLeave: () => {
        catPanel.handleClose()
      },
    },
    {
      label: 'Productos',
      key: '/products',
      onClick: () => navigate('/products'),
    },
    {
      label: (
        <a target="_blank" href={`${config.files.storePDF}`}>
          <Button type="text" icon={<DownloadOutlined />}>
            Catalogo PDF
          </Button>
        </a>
      ),
      key: '/catalogo',
    },
    {
      label: (
        <Button onClick={createReq} type="text" loading={loading}>
          Crear requerimiento
        </Button>
      ),
      key: '/requirement',
    },
  ]

  useEffect(() => {
    if (menuRef.current) {
      setMenuRect(menuRef.current.getBoundingClientRect())
    }
  }, [])

  return (
    <MenuStyled ref={menuRef}>
      <AntMenu
        inlineCollapsed={false}
        items={items}
        mode="horizontal"
        selectedKeys={[selectedKey]}
      />

      <RequirementFormModal
        onSuccess={props.modalReq.handleClose}
        open={props.modalReq.open}
        handleClose={props.modalReq.handleClose}
      />
    </MenuStyled>
  )
}

export default Menu
