import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Spin } from 'antd'

import { obtainAccount } from 'src/api/graphql'

import config from 'src/config/app'
import { accountDataToSotarage } from '../adapters/account-adapters'
import { useAuth } from 'src/auth/authProvider'

interface IParams {
  id: string
  entity: string
  token?: string
}

const initialValues: IParams = {
  id: '',
  entity: '',
  token: '',
}

interface IProps {
  params: IParams
}

const UserData = (props: IProps) => {
  const { setStoragedAccount } = useAuth()
  const query = obtainAccount({ id: props.params.id })

  useEffect(() => {
    if (!query.loading && query.data) {
      const adaptedAccount = accountDataToSotarage(query.data, props.params.entity)
      setStoragedAccount(adaptedAccount)

      /* Begin - Redirection */
      const urlParams = `${props.params.id}?entity=${props.params.entity}`
      const path =
        props.params.entity === 'buyer'
          ? `${config.platform.buyer}/logged/${urlParams}`
          : `${config.platform.provider}/logged/${urlParams}`
      console.log('Saved cookies::')
      console.table({ id: props.params.id, entity: props.params.entity })
      console.log('Redirect to::', path)
      window.location.href = path
      /* End - Redirection */
    }
  }, [!query.loading && query.data])

  return (
    <div className="window-center">
      <Spin size="large" tip="Sincronizando servicios..."></Spin>
    </div>
  )
}

const Logged = () => {
  const location = useLocation()

  const [params, setParams] = useState<IParams>(initialValues)

  const { hash, pathname, search } = location

  useEffect(() => {
    if (search) {
      params.id = pathname.replaceAll('/logged/', '')
      const aux: string[] = search.replaceAll('?', '').split('&')
      aux.forEach((itm: string) => {
        if (itm.includes('entity=')) params.entity = itm.replaceAll('entity=', '')
        if (itm.includes('token=')) params.token = itm.replaceAll('token=', '')
      })
      setParams({ ...params })
    }
  }, [search])

  return params.id ? (
    <UserData params={params} />
  ) : (
    <div className="window-center">
      <Spin size="large" tip="Sincronizando servicios..."></Spin>
    </div>
  )
}

export default Logged
