import { useEffect } from 'react'
import { Spin } from 'antd'

import storage from 'src/shared/helpers/storage'
import config from 'src/config/app'

const Logout = () => {
  useEffect(() => {
    storage.clean()
    window.location.href = `${config.platform.landing}`
  }, [])

  return (
    <>
      <div className="allCenter">
        <Spin tip="Cerrando sesión..." />
      </div>
    </>
  )
}

export default Logout
