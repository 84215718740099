import { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Pagination, Tag } from 'antd'
import { PlusCircleFilled } from '@ant-design/icons'

import { Product } from 'src/interfaces/ecommerce'

import { FilterKeyEnum, UseProductsArgs, useCart, useFilters, useProducts } from 'src/shared/hooks'

import { Empty, QButton, QCard, QLoading } from 'src/shared/components'
import format from 'src/shared/helpers/format'

interface ListProps {
  products: any
  loading: boolean
  pageInfo: any
  setPageInfo?: any
}

export const List: FC<ListProps> = ({ products, loading, pageInfo, setPageInfo }) => {
  const { addProductToCart: add } = useCart()

  const handlePage = (page: number) => setPageInfo?.({ ...pageInfo, currentPage: page })

  return (
    <div className="body">
      <QLoading loading={loading}>
        <div className="list-products">
          {products?.map((product: Product, index: number) => (
            <QCard key={product.id + index} style={{ padding: 0 }} isRounded isBordered hasShadow>
              <Link to={`/products/${product.id}`}>
                <QCard.Image images={[{ url: product.imageUrl, alt: product.name }]} />
              </Link>
              <QCard.Body style={{ padding: '10px', borderTop: '1px solid #DEE2E6' }}>
                <Link to={`/products/${product.id}`}>
                  <p className="product-name">{`${product.name}`}</p>
                </Link>
                <Tag>{product.brand}</Tag>
                <QButton
                  block
                  type="link"
                  size="large"
                  style={{
                    fontSize: 16,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  icon={<PlusCircleFilled className="icon" />}
                  onClick={() => add(product)}
                >
                  Agregar
                </QButton>
              </QCard.Body>
            </QCard>
          ))}
        </div>
        {pageInfo?.currentPage > 1 || products?.length >= 20 ? (
          <div className="footer">
            <Pagination
              current={pageInfo?.currentPage}
              pageSize={pageInfo?.perPage}
              total={pageInfo?.total}
              showSizeChanger={false}
              onChange={handlePage}
            />
          </div>
        ) : null}
        <Empty
          totalPerPage={products?.length}
          indexCurrentPage={pageInfo?.currentPage}
          total={pageInfo?.total}
        />
      </QLoading>
    </div>
  )
}
interface ListProductsProps {
  q: string | null
  pageInfo: any
  setPageInfo: any
}

export const ListProducts: FC<ListProductsProps> = ({ q, pageInfo, setPageInfo }) => {
  const { getQueryParams } = useFilters()
  const queryParams = getQueryParams([FilterKeyEnum.brands, FilterKeyEnum.categories])
  const { categories: categoriesParams, brands: brandsParams } = queryParams

  const vars: UseProductsArgs = {
    pagination: { page: pageInfo?.currentPage, perPage: pageInfo?.perPage },
    filters: {
      q,
      categories: categoriesParams ? format.splitCommaNoSpace(categoriesParams) : [],
      brands: brandsParams ? format.splitCommaNoSpace(brandsParams) : [],
    },
  }
  const { products, loading, paginated } = useProducts(vars)

  useEffect(() => {
    if (paginated) setPageInfo(paginated)
  }, [paginated])

  useEffect(() => {
    if (q) setPageInfo((prev: any) => ({ ...prev, currentPage: 1 }))
  }, [q])

  return (
    <List products={products} loading={loading} pageInfo={pageInfo} setPageInfo={setPageInfo} />
  )
}
