import { useQuery, useLazyQuery } from '@apollo/client'

import { queryGetLegal } from '../queries/getLegal'
import { queryListCategories } from '../queries/getCategories'
import { Input, Paginated, ResponsePaginated } from '../../graphql.types'
import { Platforms } from 'src/interfaces/types/app'
import { queryFilters } from '../queries/filters'

export type InputLegal = Input<{ ruc: string }>

export const useLazyGetLegal = () => {
  return useLazyQuery<{ getLegal: any }, InputLegal>(queryGetLegal, {
    context: { clientName: Platforms.business },
  })
}

export const useListCategories = (paginated: Paginated, filter?: any) => {
  return useQuery<{ listCategories?: ResponsePaginated<'categories'> }>(queryListCategories, {
    context: { clientName: Platforms.business },
    variables: { paginated, filter },
  })
}

export const useListFilters = (input?: any) => {
  return useQuery<{ filters?: any }>(queryFilters, {
    context: { clientName: Platforms.business },
    variables: { input },
  })
}
