import { labelSearch } from './../../layouts/Navbar/Search'
import { useEffect, useState } from 'react'

import { listInventoriesParents } from 'src/api/graphql'

import { Inventory } from 'src/interfaces/backend'
import { Product } from 'src/interfaces/ecommerce'

import Transforms from 'src/shared/helpers/transforms'

export interface UseProductsArgs {
  pagination?: {
    page: number
    perPage: number
    sortField?: String
    sortOrder?: String
  }
  filters?: {
    providerId?: string
    q?: string | null
    ruc?: string
    categories?: string[] | null
    brands?: string[] | null
    names?: string[] | null
    priorBrand?: string | null
    updatedAt?: string
    createdAt?: string
  }
}

export const useProducts = (args: UseProductsArgs) => {
  const { pagination = { page: 1, perPage: 9 }, filters = {} } = args && args

  const [products, setProducts] = useState<Product[]>([])
  const [options, setOptions] = useState<{ label: JSX.Element; value: string }[]>([])

  const query = listInventoriesParents(pagination, filters)

  useEffect(() => {
    if (!query.loading && query.data) {
      let dt: any = query.data.ecommerce.inventories
      if (args.filters?.names?.length) {
        dt = []
        args.filters.names.forEach((prName: string) => {
          const found = query.data.ecommerce.inventories.find((inv: any) => inv.product == prName)
          if (found) dt.push(found)
        })
      }
      const prds: Product[] = dt.map((inventory: Inventory) =>
        Transforms.inventory2product(inventory)
      )
      setProducts(prds)
      setOptions(prds?.map((p) => ({ label: labelSearch(p.name), value: p.name, key: p.id })))
    }
  }, [!query.loading && query.data])

  return {
    products,
    setProducts,
    options,
    paginated: query.data?.ecommerce?.pageInfo,
    ...query,
  }
}
