import { ReactNode, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AutoComplete, Checkbox, Input, InputRef } from 'antd'
import { SearchOutlined, ArrowLeftOutlined } from '@ant-design/icons'

import { useFilters, FilterKeyEnum, useDialog } from 'src/shared/hooks'

import { CheckboxStyled, SearchGroup, SearchMobile } from './styles'

const EnterButton = (
  <>
    <SearchOutlined />
    <span className="text-md">Buscar</span>
  </>
)
export const labelSearch = (label: ReactNode) => (
  <div style={{ display: 'flex', alignItems: 'center', gridGap: '0.5rem' }}>
    <SearchOutlined style={{ color: '#D9D9D9' }} />
    {label}
  </div>
)

const Search = () => {
  const [q, setQ] = useState<string | undefined>()
  const [provider, setProvider] = useState<string | undefined>()
  const inputRef = useRef<InputRef>(null)
  const location = useLocation()

  const { applyNavigateFilter, getQueryParams } = useFilters()
  const { q: qParam } = getQueryParams([FilterKeyEnum.q])
  const { open, handleToggle } = useDialog(true)
  const searchMobile = useDialog()

  const handleSearch = (value: string) => {
    if (provider && open) {
      applyNavigateFilter(location.pathname, [['q', value]])
      return
    }
    applyNavigateFilter('/products', [['q', value]])
  }

  const handleChange = (value: string) => {
    setQ(value)
  }

  useEffect(() => {
    if (location.pathname !== '/products') {
      setQ('')
    }
    if (
      location.pathname.split('/')[1] === 'providers' &&
      location.pathname.split('/').length > 2
    ) {
      setProvider(decodeURIComponent(location.pathname.split('/')[2]))
    } else {
      setProvider(undefined)
    }
  }, [location])

  useEffect(() => {
    if (searchMobile.open) {
      inputRef?.current?.focus()
    }
  }, [searchMobile.open])

  useEffect(() => {
    if (!qParam || qParam?.length === 0) {
      setQ('')
    }
  }, [qParam])

  return (
    <>
      <SearchGroup size="large">
        <AutoComplete
          popupMatchSelectWidth={500}
          defaultValue={qParam}
          value={document.body.clientWidth <= 576 ? qParam : q}
          onChange={handleChange}
          onSelect={handleSearch}
        >
          <Input.Search
            placeholder="Buscar productos"
            enterButton={EnterButton}
            onSearch={handleSearch}
            onFocus={(ev) => {
              if (document.body.clientWidth <= 576) {
                ev.currentTarget.blur()
                searchMobile.handleOpen()
                inputRef.current?.focus({
                  cursor: 'start',
                })
                inputRef.current?.select()
              }
            }}
            suffix={
              provider ? (
                <CheckboxStyled onChange={handleToggle} checked={open}>
                  {`Solo en ${provider}`}
                </CheckboxStyled>
              ) : (
                <span />
              )
            }
          />
        </AutoComplete>
      </SearchGroup>
      {searchMobile.open && (
        <SearchMobile>
          <Input.Search
            prefix={
              <ArrowLeftOutlined style={{ cursor: 'pointer' }} onClick={searchMobile.handleClose} />
            }
            allowClear
            suffix={false}
            size="large"
            ref={inputRef}
            defaultValue={qParam ?? ''}
            bordered={false}
            placeholder="Buscar productos"
            onSearch={(value) => {
              handleSearch(value)
              searchMobile.handleClose()
            }}
            onBlur={searchMobile.handleClose}
          />
          {provider ? (
            <Checkbox style={{ paddingLeft: 10 }} onChange={handleToggle} checked={open}>
              {`Solo en ${provider}`}
            </Checkbox>
          ) : (
            <span />
          )}
        </SearchMobile>
      )}
    </>
  )
}
export default Search
