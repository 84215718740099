import { gql } from '@apollo/client'

export const queryObtainRequirement = gql`
  query obtainRequirement($input: InputID) {
    obtainRequirement(input: $input) {
      _id
      deliveryAddress
      code
      number
      name
      categories
      subcategories
      cotizator
      asessor
      providers
      deliveries
      requirementUrls
      techsheetUrls
      status
      operation
      origin
      processing
      accountId
      requirementVid
      createdAt
      updatedAt
      requirementUrls
      techsheetUrls
    }
  }
`
