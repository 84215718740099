import { useQuery } from '@apollo/client'

import { Platforms } from 'src/interfaces/types/app'

import {
  queryECommerce,
  queryFindBrand,
  queryListBrands,
  queryListFavorites,
  queryListInventories,
  queryListInventoriesParents,
  queryObtainInventory,
} from '../queries/inventories'

export const listInventories = (paginated: any, filter: any) => {
  const response = useQuery(queryListInventories, {
    context: { clientName: Platforms.business },
    variables: { paginated, filter },
    fetchPolicy: 'cache-and-network',
  })
  return response
}

export const obtainInventory = (input: any) => {
  const response = useQuery(queryObtainInventory, {
    context: { clientName: Platforms.business },
    variables: { input },
  })
  return response
}

export const listBrands = (paginated: any, filter: any) => {
  return useQuery<{ listBrands: any }>(queryListBrands, {
    context: { clientName: Platforms.business },
    variables: { paginated, filter },
  })
}

export const findBrand = (params: { name: string } | { id: string }) => {
  const response = useQuery(queryFindBrand, {
    context: { clientName: Platforms.business },
    variables: { params },
  })
  return response
}

export const listInventoriesParents = (paginated: any, filter: any) => {
  return useQuery(queryECommerce, {
    context: { clientName: Platforms.business },
    variables: { paginated, filter },
    fetchPolicy: 'cache-and-network',
  })
}

export const listFavorites = () => {
  const response = useQuery(queryListFavorites, {
    context: { clientName: Platforms.business },
    variables: {},
    fetchPolicy: 'cache-and-network',
  })
  return response
}
