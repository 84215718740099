import { gql } from '@apollo/client'

export const queryListInvs = gql`
  query getInventories($paginated: Paginated, $filter: FilterListInventory) {
    listInventories(paginated: $paginated, filter: $filter) {
      pageInfo {
        total
        currentPage
        perPage
        hasPreviousPage
        hasNextPage
      }
      inventories {
        key: _id
        product
        skuEquip
        skuParent
        sku
        unity
        stock
        currency
        price
        pvp
        igv
        vol1
        pricevol1
        vol2
        pricevol2
        brand
        categories
        subcategories
        deepcategories
        image0
        image1
        image2
        image3
        description
        variant
        color
        presentation
        type
        model
        size
        measure
        thickness
        weight
        fabricator
        material
        reference
        techsheetUrl
        warrantyUrl
        deliveryAmountToFree
        deliveryLimaPrice
        deliveryProvincePrice
        deliveryPolicy
      }
    }
  }
`

export const queryObtainStructure = gql`
  query obtainStructure($input: InputObtainStructure) {
    obtainStructure(input: $input) {
      _id
      portal
      banners {
        imageUrl
        imageMobileUrl
        redirectTo
      }
      benefits {
        title
        description
        iconUrl
      }
    }
  }
`
