import { gql } from '@apollo/client'

export const mutationAddRequirement = gql`
  mutation addRequirement($input: InputAddRequirement) {
    addRequirement(input: $input) {
      _id
      code
      number
      name
      categories
      subcategories
      cotizator
      asessor
      providers
      deliveries
      requirementUrls
      techsheetUrls
      status
      operation
      origin
      processing
      accountId
      requirementVid
      createdAt
      updatedAt
    }
  }
`

export const mutationUpdRequirement = gql`
  mutation updRequirement($input: InputUpdRequirement) {
    updRequirement(input: $input) {
      _id
      deliveryAddress
      code
      number
      name
      categories
      subcategories
      cotizator
      asessor
      providers
      deliveries
      requirementUrls
      techsheetUrls
      status
      operation
      origin
      processing
      accountId
      requirementVid
      createdAt
      updatedAt
      requirementUrls
      techsheetUrls
    }
  }
`
