import { Card } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import styled from 'styled-components'

export const FormItemStyle = styled(FormItem)`
  .ant-form-item-control-input input {
    border-radius: 8px;
    background: #f0f1f3;
    width: 100%;
    padding: 20px 28px;
    box-sizing: border-box;
    line-height: initial;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
    opacity: 0.6;
    &::placeholder {
      color: #111d5e;
    }
    &::hover {
      .ant-form-item-control-input input {
        border: none;
      }
    }
  }
  .ant-input-affix-wrapper.ant-input-password {
    border-radius: 8px;
    background: #f0f1f3;
    &:hover {
      background: #ffffff;
      input {
        background: #ffffff;
      }
    }
  }
  .ant-form-item-control-input input[type='password'] {
    border: none;
    width: 100%;
    padding: 20px 28px;
    background: #f0f1f3;
    box-sizing: border-box;
    line-height: initial;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
    opacity: 0.6;
    &::placeholder {
      color: #111d5e;
    }
  }
`

export const CardStyled = styled(Card)`
  border-radius: 0;
  width: 100vw;
  .ant-card-body {
    padding: 40px 20px;
    height: calc(100vh - 90px);
  }
  @media (min-width: 640px) {
    border-radius: 16px;
    min-width: 550px;
    max-width: 550px;

    .ant-card-body {
      padding: 25px 40px 40px;
      height: 100%;
    }
  }
`

export const CenterStyled = styled.div`
  height: 100vh;
  display: flex;
  align-items: flex-end;
  @media (min-width: 640px) {
    justify-content: center;
    align-items: center;
  }
`
